import(/* webpackMode: "eager" */ "/home/admin/data/pokrs/node_modules/next/font/local/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"\",\"arguments\":[{\"src\":\"./fonts/GeistVF.woff\",\"variable\":\"--font-geist-sans\",\"weight\":\"100 900\"}],\"variableName\":\"geistSans\"}");
;
import(/* webpackMode: "eager" */ "/home/admin/data/pokrs/node_modules/next/font/local/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"\",\"arguments\":[{\"src\":\"./fonts/GeistMonoVF.woff\",\"variable\":\"--font-geist-mono\",\"weight\":\"100 900\"}],\"variableName\":\"geistMono\"}");
;
import(/* webpackMode: "eager" */ "/home/admin/data/pokrs/src/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/home/admin/data/pokrs/src/components/ui/toaster.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["TRPCProvider"] */ "/home/admin/data/pokrs/src/providers/trpc-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["UserProvider"] */ "/home/admin/data/pokrs/src/providers/user-provider.tsx");
