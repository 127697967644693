import { createTRPCReact } from '@trpc/react-query'
import { httpBatchLink } from '@trpc/client/links/httpBatchLink'
import type { AppRouter } from '@/server/api/root'

export const api = createTRPCReact<AppRouter>()

export const links = [
  httpBatchLink({
    url: '/api/trpc',
  }),
] 