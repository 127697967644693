'use client'

import * as React from 'react'
import { api } from '@/lib/api'
import type { Session } from 'next-auth'
import { TRPCClientError } from '@trpc/client'
import { User } from '@prisma/client'

interface UserContextType {
  user: User | null
  session: Session | null
  isLoading: boolean
  error: Error | null
}

const UserContext = React.createContext<UserContextType | undefined>(undefined)

export function UserProvider({
  children,
  initialSession,
}: {
  children: React.ReactNode
  initialSession: Session | null
}) {
  const [session] = React.useState<Session | null>(initialSession)
  const {
    data: user,
    isLoading,
    error,
  } = api.user.getCurrent.useQuery(undefined, {
    enabled: !!session?.user?.id,
    retry: false,
    select: (data) => ({
      ...data,
      emailVerified: null,
    }),
    onError: (error) => {
      if (error instanceof TRPCClientError) {
        if (
          error.data?.code !== 'NOT_FOUND' &&
          error.data?.code !== 'UNAUTHORIZED'
        ) {
          console.error('User fetch error:', error)
        }
      }
    },
  })

  const value = React.useMemo(
    () => ({
      user: user ?? null,
      session,
      isLoading: isLoading && !!session?.user?.id,
      error:
        error instanceof TRPCClientError &&
        (error.data?.code === 'NOT_FOUND' ||
          error.data?.code === 'UNAUTHORIZED')
          ? null
          : (error as Error | null),
    }),
    [user, session, isLoading, error]
  )

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>
}

export function useUser() {
  const context = React.useContext(UserContext)
  if (context === undefined) {
    throw new Error('useUser must be used within a UserProvider')
  }
  return context
}
