'use client'

import { useState } from 'react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { httpBatchLink } from '@trpc/client'
import { api } from '@/lib/api'
import superjson from 'superjson'
import type { TRPCClientError } from '@trpc/client'
import type { AppRouter } from '@/server/api/root'

export function TRPCProvider({ children }: { children: React.ReactNode }) {
  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            retry: (failureCount: number, error: unknown) => {
              const trpcError = error as TRPCClientError<AppRouter>
              if (
                trpcError?.data?.code === 'FORBIDDEN' &&
                trpcError?.message?.includes('subscription')
              ) {
                return false // Don't retry subscription errors
              }
              return failureCount < 3
            },
            onError: (error: unknown) => {
              const trpcError = error as TRPCClientError<AppRouter>
              if (
                trpcError?.data?.code === 'FORBIDDEN' &&
                trpcError?.message?.includes('subscription')
              ) {
                return // Silently handle subscription errors
              }
              console.error('Query error:', error)
            },
          },
          mutations: {
            onError: (error: unknown) => {
              const trpcError = error as TRPCClientError<AppRouter>
              if (
                trpcError?.data?.code === 'FORBIDDEN' &&
                trpcError?.message?.includes('subscription')
              ) {
                return // Silently handle subscription errors
              }
              console.error('Mutation error:', error)
            },
          },
        },
      })
  )

  const [trpcClient] = useState(() =>
    api.createClient({
      transformer: superjson,
      links: [
        httpBatchLink({
          url: '/api/trpc',
        }),
      ],
    })
  )

  return (
    <api.Provider client={trpcClient} queryClient={queryClient}>
      <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
    </api.Provider>
  )
}
